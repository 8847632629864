import React from 'react'
import { Link } from 'react-router-dom'

const TopNavTrainee = ({courseId,selectedCourse,moduleName,moduleId,lessonId,lessonName, type}) => {
  return (
    <nav
            class="flex mx-12 my-4 "
            aria-label="Breadcrumb"
          >
            <ol class="md:inline-flex md:flex items-center justify-center space-x-1 md:space-x-1 rtl:space-x-reverse">
              <li class="inline-flex items-center">
                <Link
                  to="/training"
                  class="inline-flex items-center text-base font-medium text-[#BDBEBE]"
                >
                  <svg
                    class="w-3 h-3 me-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                  </svg>
                  Home
                </Link>
              </li>
              <li>
                <div class="flex items-center">
                  <svg
                    class="rtl:rotate-180 w-3 h-3 text-[#BDBEBE]"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <svg
                    class="rtl:rotate-180 w-3 h-3 text-[#BDBEBE] me-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <Link
                    to={`/training`}
                    className="inline-flex items-center text-base font-medium text-[#BDBEBE]"
                  >
                    Courses
                  </Link>
                </div>
              </li>
             {selectedCourse && 
              (

              <li>
                <div class="flex items-center">
                  <svg
                    class="rtl:rotate-180 w-3 h-3 text-[#BDBEBE]"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <svg
                    class="rtl:rotate-180 w-3 h-3 text-[#BDBEBE] me-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <Link
                    to={`/training/courses/${courseId}`}
                    className="inline-flex items-center text-base font-medium text-[#BDBEBE]"
                  >
                    {selectedCourse?.course_name}
                  </Link>
                </div>
              </li>
              )} 

{moduleName && (

              <li>
                <div class="flex items-center">
                  <svg
                    class="rtl:rotate-180 w-3 h-3 text-[#BDBEBE]"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <svg
                    class="rtl:rotate-180 w-3 h-3 text-[#BDBEBE] me-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <Link
                    to={`/training/courses/${courseId}/module/${moduleId}`}
                    state={{type: type}}
                    className="inline-flex items-center text-base font-medium text-[#BDBEBE]"
                  >
                  {moduleName}
                  </Link>
                </div>
              </li>

)}

              {lessonName && (
                <li>
                  <div className="flex items-center">
                    <svg
                      class="rtl:rotate-180 w-3 h-3 text-[#BDBEBE]"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 9 4-4-4-4"
                      />
                    </svg>
                    <svg
                      class="rtl:rotate-180 w-3 h-3 text-[#BDBEBE] me-2.5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 9 4-4-4-4"
                      />
                    </svg>
                    <Link
                      to={`/training/courses/${courseId}/module/${moduleId}/lesson/${lessonId}`}
                      state={{type: type}}
                      className="inline-flex items-center text-base font-medium text-[#BDBEBE]"
                    >
                     {lessonName}
                    </Link>
                  </div>
                </li>
              )}
            </ol>
          </nav>
  )
}

export default TopNavTrainee
