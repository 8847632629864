/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "styles/Consultant.css";
import logo_gray from "../../data/logo-gray.svg";
import logo_only from "../../data/logo-ico.svg";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import {
  AutoStories,
  ContactSupport,
  Logout,
  Person3,
} from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import { Tooltip } from "@mui/material";
import { useLogoutFunction } from "@propelauth/react";
import PollinatorLogo from "../../data/pollinator-logo.png";
import { FaExclamationCircle, FaArrowRight } from "react-icons/fa";

const sidebar = ({ sidebarOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const logout = useLogoutFunction();
  const handleLogout = (event) => {
    event.preventDefault();
    console.log("Logging out...");
    localStorage.removeItem("__PROPEL_AUTH_LOGGED_IN_AT");
    localStorage.removeItem("__PROPEL_AUTH_LOGGED_OUT_AT");
    localStorage.removeItem("auth");
    logout(false);
    // navigate("/");

    // window.location.href = "/";
    navigate("/");
  };

  const handleOpenLogoutModal = () => {
    setOpenLogoutModal(true);
  };
  const handleCloseLogoutModal = () => setOpenLogoutModal(false);

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <>
      <style>
        {`
      .sidebar::before {
        content: "";
        
        position: relative;
        bottom: 0;
        left: -10%;
        background: url(${logo_gray}) no-repeat bottom / contain;
      }
      `}
      </style>
      <aside
        className="w-[4.5rem] flex flex-col bg-[#1A1C1E] text-white fixed inset-y-0 left-0 transition-all duration-300 ease-in-out  sidebar "
      >
        <nav className="flex-1 flex flex-col bg-[#1A1C1E] border-r border-[#37383A] pt-4">
          <Link
            to="/training"
            className="flex items-center w-full gap-3 text-xl font-extrabold tracking-tight text-white uppercase dark:text-white"
          >
            <span className="w-full p-5 pt-0">
              <img src={logo_only} alt="logo"></img>
            </span>
          </Link>
          <Tooltip title="Training" placement="right">
            <Link
              to="/training"
              className={`nav-link ${isActive("/training") ? "active" : ""}`}
            >
              {<AutoStories />}
            </Link>
          </Tooltip>
          <Tooltip title="Interview Prep" placement="right">
            <Link
              to="/training/interviewprep"
              className={`nav-link ${
                isActive("/training/interviewprep") ? "active" : ""
              }`}
            >
              {<CoPresentIcon />}
            </Link>
          </Tooltip>
          <Tooltip title="Pollinator" placement="right">
            <Link
              to="/training/pollinator"
              className={`nav-link ${
                isActive("/training/pollinator") ? "active" : ""
              }`}
            >
              <img
                src={PollinatorLogo}
                alt="pollinator"
                height={24}
                width={24}
              />
            </Link>
          </Tooltip>
          <Tooltip title="Mentor" placement="right">
            <Link
              to="/training/mentor"
              className={`nav-link ${
                isActive("/training/mentor") ? "active" : ""
              }`}
            >
              <Person3 />
            </Link>
          </Tooltip>
         
         
          <div className="flex-grow flex flex-col-reverse z-50 mb-16">
            <Tooltip title="Logout" placement="right">
              <Link onClick={handleOpenLogoutModal} className="nav-link">
                {<Logout />}
              </Link>
            </Tooltip>
            <Tooltip title="Hands on Support" placement="right">
              <Link
                to="https://discord.gg/qstwvtbQEw"
                target="_blank"
                className="nav-link"
              >
                {<ContactSupport />}
              </Link>
            </Tooltip>
          </div>
        </nav>
      </aside>
      <Modal
  open={openLogoutModal}
  onClose={handleCloseLogoutModal}
  className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50 "
>
  <div className="bg-[#1f1f1f] p-2 rounded-lg shadow-lg sm:w-[200px] sm:h-[300px] flex flex-col justify-center items-center text-center">
    <FaExclamationCircle size={30} className="text-white mb-2" />
    <p className="text-white text-sm font-semibold">Are You Sure toLogout?</p>
    <div className="flex flex-wrap space-y-2 mt-2 w-full">
      <button
        onClick={handleCloseLogoutModal}
        className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-medium py-1 px-2 rounded w-full text-sm"
      >
        Cancel
      </button>
      <button
        onClick={handleLogout}
        className="bg-[#5B53E7] hover:bg-red-600 text-white font-medium py-1 px-2 rounded w-full text-sm flex items-center justify-center"
      >
        <span>Yes</span> <FaArrowRight className="ml-1" />
      </button>
    </div>
  </div>
</Modal>
    </>
  );
};

export default sidebar;


